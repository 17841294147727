@import "../../style/vertical-divider";

.inspection-list-item {
  border-radius: 4px;
  cursor: pointer;
  background-color: $okcomply-white;
  color: $okcomply-black;
  text-decoration: none;
  margin-bottom: 0.5rem;
  &
  &:hover {
      box-shadow: $card-shadow;
  }

}

.inspection-list-item-rows {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-items: center;
  @include tablet-down {
    flex-direction: column;
  }
}

.inspection-list-item-row {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  &.wide {
    flex: 1.3;
}

  &.thin {
    flex: .5;
}

  @include tablet-down {
    flex-direction: row;
  }

  &.at-end {
    align-items: flex-end;
  }

  &.center {
    align-items: center;
  }

  @include tablet-down {
    &:first-of-type {
      padding-bottom: 0.7rem;

      .label { // label not visible first row tablet down
        display: none;
      }

    }
    &:nth-of-type(2) { // list-item-row-single 2de
      margin: 0 -1rem 0.5rem -1rem;  //trbl
      margin-left: -1rem;
      margin-right: -1rem;
      margin-bottom: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 1rem;
      border-bottom: solid 1px $okcomply-gray;
      flex-direction: column;

      .label { // label not visible second row tablet down
        display: none;
      }
    }
  }

  .image-location {
    display: flex;
    align-self: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

}

.inspection-list-item-column {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    &.bold {
      font-weight: bold;
   }

  // items next to each other tablet and down
  @include tablet-down {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: space-between;
  }

  // equally divide space up between label and item
  .label {
    display: flex;
    flex-basis: 50%;
    flex-grow: 1;
    font-weight: normal;

    @include desktop { // don't show label on desktop
      flex-grow: 0;
      display: none !important;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    flex-grow: 1;
    align-items: flex-start;

    &.is-center { // organisation item
      align-self: center;
    }

    &.is-row {
      flex-direction: row;
    }

    &.is-bold {
      font-weight: bold;
    }

    div {
      .is-column {
        flex-direction: column;
      }

      p {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        color: #000000;

      }

    }

  }

}
